import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import { Root as DropdownRoot, Portal, Sub, SubContent, SubTrigger } from "@radix-ui/react-dropdown-menu";
import type {
  DropdownMenuContentProps,
  DropdownMenuItemProps,
  DropdownMenuLabelProps,
  DropdownMenuProps,
  DropdownMenuSeparatorProps,
} from "@radix-ui/react-dropdown-menu";
import { useControllableState } from "@radix-ui/react-use-controllable-state";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import React, { createContext } from "react";
import { Text } from "../text/text";
import { cn } from "../utils";
import { dropdown } from "./dropdown.styles";

/* -------------------------------------------------------------------------------------------------
 * Root
 * -------------------------------------------------------------------------------------------------
 * This is mainly a wrapper around the 'react-dropdown-menu' Root primitive with additional context.
 * The Root component does not need ref forwarding
 */

const CustomContext = createContext<{ onOpenChange?: (open: boolean) => void; open: boolean }>({ open: false });
const Root = (props: DropdownMenuProps) => {
  // In order to simplify the consuming api we can use context here to allow the content access to the root state. This is required for framer motion to determine if the content should mount/unmount
  // https://github.com/radix-ui/primitives/issues/1281
  const { open: openProp, onOpenChange, defaultOpen } = props;
  const [open = false, setOpen] = useControllableState({
    prop: openProp,
    defaultProp: defaultOpen,
    onChange: onOpenChange,
  });

  return (
    <CustomContext.Provider value={React.useMemo(() => ({ onOpenChange: setOpen, open }), [setOpen, open])}>
      <DropdownRoot {...props} open={open} onOpenChange={setOpen} />
    </CustomContext.Provider>
  );
};

const Trigger = React.forwardRef<HTMLButtonElement, React.ComponentProps<typeof DropdownMenuPrimitive.Trigger>>(
  function Trigger(props, ref) {
    return <DropdownMenuPrimitive.Trigger {...props} ref={ref} className={cn(dropdown.trigger(), props.className)} />;
  },
);

/* -------------------------------------------------------------------------------------------------
 * Content
 * -------------------------------------------------------------------------------------------------
 * This is mainly a wrapper around the 'react-dropdown-menu' Content primitive with some custom styling and animation.
 * The portal props are using the defaults are unlikely to be used. Until then we can simplify the api
 * by using it here and not exposing it in the UI package.
 *
 * The ref prop is forwarded to the content component
 */

/** These are the default styles  */
type DropdownMenuContentElement = React.ElementRef<typeof DropdownMenuPrimitive.Content>;

const Content = React.forwardRef<DropdownMenuContentElement, DropdownMenuContentProps & { scrollable?: boolean }>(
  function DropdownMenuContent(props: DropdownMenuContentProps & { scrollable?: boolean }, forwardedRef) {
    // Use the context value from the root to determine if the content should mount/unmount
    const { open } = React.useContext(CustomContext);

    const { className, scrollable, children, ...rest } = props;

    return (
      <AnimatePresence>
        {open ? (
          // although we are exporting as the Content component it will contain the Portal component under the hood to avoid repetition
          <DropdownMenuPrimitive.Portal forceMount>
            <DropdownMenuPrimitive.Content
              {...rest}
              ref={forwardedRef}
              className={clsx(dropdown.root({ scrollable }), className, scrollable && "scrollable-dropdown")}
              asChild
            >
              <motion.div
                initial={{ opacity: 0.2, translateY: -3 }}
                animate={{ opacity: 1, translateY: 0 }}
                exit={{ opacity: 0, translateY: -3 }}
                transition={{ duration: 0.1, ease: "easeInOut" }}
                onClick={(e) => e.stopPropagation()}
              >
                {children}
              </motion.div>
            </DropdownMenuPrimitive.Content>
          </DropdownMenuPrimitive.Portal>
        ) : null}
      </AnimatePresence>
    );
  },
);

/* -------------------------------------------------------------------------------------------------
 * Item
 * -------------------------------------------------------------------------------------------------
 * This is a simple wrapper around the 'react-dropdown-menu' item primitive with some custom styling.
 */

type DropdownMenuItemElement = React.ElementRef<typeof DropdownMenuPrimitive.Item>;
const Item = React.forwardRef<DropdownMenuItemElement, DropdownMenuItemProps & { noHover?: boolean }>(
  function DropdownMenuItem(props, forwardedRef) {
    const { className, noHover, ...rest } = props;
    return (
      <DropdownMenuPrimitive.Item {...rest} ref={forwardedRef} className={cn(dropdown.item({ noHover }), className)} />
    );
  },
);

/* -------------------------------------------------------------------------------------------------
 * Separator
 * -------------------------------------------------------------------------------------------------
 * This is a simple styled separator
 */

type DropdownMenuSeparatorElement = React.ElementRef<typeof DropdownMenuPrimitive.Separator>;
const Separator = React.forwardRef<DropdownMenuSeparatorElement, DropdownMenuSeparatorProps>(
  function DropdownMenuSeparator(props: DropdownMenuSeparatorProps, forwardedRef) {
    const { className, ...rest } = props;
    return (
      <DropdownMenuPrimitive.Separator {...rest} ref={forwardedRef} className={clsx(dropdown.separator(), className)} />
    );
  },
);

/* -------------------------------------------------------------------------------------------------
 * Label
 * -------------------------------------------------------------------------------------------------
 * This is a simple styled Label
 */

type DropdownMenuLabelElement = React.ElementRef<typeof DropdownMenuPrimitive.Label>;
const Label = React.forwardRef<DropdownMenuLabelElement, DropdownMenuLabelProps>(function DropdownMenuLabel(
  props: DropdownMenuLabelProps,
  forwardedRef,
) {
  const { className, children, ...rest } = props;

  return (
    <DropdownMenuPrimitive.Label {...rest} ref={forwardedRef} asChild>
      <Text size="sm" color="muted" className={cn(dropdown.label(), className)}>
        {children}
      </Text>
    </DropdownMenuPrimitive.Label>
  );
});

export { Root, Trigger, Content, Item, Separator, Label, Sub, Portal, SubTrigger, SubContent };
